<template>
  <loader v-if="loading" />

  <form
    v-else
    autocomplete="off"
    id="class-form"
    class="column form-component"
    @submit.prevent="submitEventForm"
  >
    <p>Fields marked with (<span class="error--text">*</span>) are required.</p>

    <label for="className">
      <span :class="requiredField('name')">
        <i class="accent--text fas fa-info-circle"></i>
        <span>&nbsp;What Class is this?</span>
      </span>
      <select name="className" v-model="form.name">
        <option :value="null">Select a class:</option>
        <option
          v-for="classTitle in activeTitles"
          :key="classTitle.id"
          :value="classTitle.title"
        >
          {{ classTitle.title }}
        </option>
      </select>
    </label>

    <!-- Instructors -->
    <event-form-teachers
      v-if="isAdminUser"
      :form-data="form"
      :teachers="teachers"
      @change="appendFormData"
    />

    <!-- Schedule -->
    <event-form-schedule :form-data="form" @change="appendFormData" />

    <!-- Status -->
    <event-form-participants
      is-online-class
      :form-data="form"
      @change="appendFormData"
    />

    <!-- Image -->
    <event-form-image
      is-online-class
      :form-data="form"
      @change="appendFormData"
    />

    <!-- Zoom Settings -->
    <event-form-zoom
      is-online-class
      :form-data="form"
      :teachers="teachers"
      @change="appendFormData"
    />

    <!-- Description -->
    <event-form-description
      placeholder="Enter Class description"
      :form-data="form"
      @change="appendFormData"
    />

    <!-- Form Controls -->
    <div class="form-controls">
      <!-- Submit -->
      <button
        class="wide"
        :class="{ disabled: error, 'button--online': onlineClass }"
        type="submit"
        :disabled="error !== null"
      >
        {{ submitButtonText }}
      </button>

      <!-- Delete -->
      <button
        v-if="onlineClass"
        class="outline error--text"
        @click.prevent="confirmDelete = true"
      >
        <i class="fas fa-times-square"></i>
        Delete
      </button>
    </div>

    <p v-if="error" class="error--text">{{ error }}</p>

    <hr class="divider divider--lg" />

    <modal-component
      v-if="onlineClass"
      :title="`Delete '${data.name}'?`"
      :visible="confirmDelete"
      @close="confirmDelete = false"
    >
      <confirm-delete-event
        :error="error"
        :event="data"
        @confirm-delete="deleteEvent"
      />
    </modal-component>
  </form>
</template>

<script>
/* eslint-disable no-undef */
import { getOnlineClassTitles } from "../models/classes";
import { eventFormReset } from "../helpers/classes-events.helpers.js";
import EventFormsMixin from "./mixins/event-forms.mixin";
import ConfirmDeleteEvent from "./ConfirmDeleteEvent.vue";
import EventFormImage from "./EventForm.Image.vue";
import EventFormParticipants from "./EventForm.Participants.vue";
import EventFormSchedule from "./EventForm.Schedule.vue";
import EventFormTeachers from "./EventForm.Teachers.vue";
import EventFormZoom from "./EventForm.Zoom.vue";
import Loader from "./Loader.vue";
import ModalComponent from "./ModalComponent.vue";
import EventFormDescription from "./EventForm.Description.vue";

export default {
  components: {
    ConfirmDeleteEvent,
    EventFormDescription,
    EventFormImage,
    EventFormParticipants,
    EventFormSchedule,
    EventFormTeachers,
    EventFormZoom,
    Loader,
    ModalComponent
  },

  name: "ClassForm",

  props: { formData: Object, disableRole: Boolean },

  mixins: [EventFormsMixin],

  data: () => ({ activeClass: null, onlineClassTitles: [] }),

  watch: {
    "$route.path": function onRouteChange() {
      this.onlineClass = null;
      this.form = eventFormReset();
      this.fetchRouteData();
    }
  },

  computed: {
    activeTitles() {
      return this.onlineClassTitles.filter(t => t.active);
    },
    submitButtonText() {
      if (this.error) return " ... ";
      const action = this.onlineClass === null ? "Create" : "Update";
      return `${action} Class`;
    }
  },

  async mounted() {
    this.loading = true;
    this.onlineClassTitles = await getOnlineClassTitles();
    this.loading = false;
  }
};
</script>
