import apiConfig from "../network";

/**
 * Create a new `Online Class` title for use in the UI
 * @param {object} params request params
 * @param {string} params.title Title to create
 * @param {boolean} params.active Whether `title` can be used in UI
 */
export async function createOnlineClassTitle(params) {
  const data = await apiConfig.eventsAdmin.createOnlineClassTitle(params);
  return data;
}

/**
 * Fetch a list of all available online classes
 */
export async function getOnlineClassTitles() {
  const { data } = await apiConfig.events.getOnlineClassTitles();
  return data;
}

/**
 * Updates a single `Online Class` title. The change will not affect any existing
 * classes that use the title's former verbage.
 * @param {object} params Request params
 * @param {string|number} params.classTitleId Id of target `title`
 * @param {boolean} params.active Whether `title` can be used in the UI.
 */
export async function updateOnlineClassTitles(params) {
  const { data } = await apiConfig.eventsAdmin.updateOnlineClassTitles(params);
  return data;
}
